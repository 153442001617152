import { h, Component } from 'preact';

import Header from './header';
import Footer from './footer';
import ChatPopup from './chatpopup';
import CookiePopup from './cookiepopup';

// Code-splitting is automated for routes
import Home from '../routes/home';

export default class App extends Component {

	state = { cookiesClosed: false, currentUrlClass: '' };

  cookieAcceptedCB() {
  	// this.checkLoggedIn();
  	// loadAnal();
  }

  constructor() {
  	super();

  	this.appWrpEl = false;

  	this.headerCBFun = this.headerCB.bind(this);

  }

  componentDidMount() {
  	this.appWrpEl = document.getElementById('appWrp');
  	if (typeof window !== 'undefined') {
  		if (localStorage.getItem('cookieaccept')) {
  			this.setState({ cookiesClosed: true });
  			this.cookieAcceptedCB();
  		}
  	}
  }

	headerCB = e => {
		e.preventDefault();
		if (this.appWrpEl) {
			if (this.appWrpEl.classList.contains('openMenu'))
				this.appWrpEl.classList.remove('openMenu');
			else
				this.appWrpEl.classList.add('openMenu');
		}
	};

	closePopup() {
		this.setState({ ytData: null });
	}

	acceptCookies (accept) {
		if (typeof window !== 'undefined') {
			if (accept) {
				localStorage.setItem('cookieaccept', true);
				this.cookieAcceptedCB();
			}
		}
		this.setState({ cookiesClosed: true });
	}

	render(props, state) {

		return (<div id="appWrp" className={state.currentUrlClass}>
			<Header cb={this.headerCBFun} u={state.user} isStudio={state.isStudio} />
			<main>
				<Home />
			</main>
			<Footer />
			{/*{ state.cookiesClosed ? '' : <CookiePopup cb={this.acceptCookies.bind(this)} /> }*/}
		</div>);
	}
}
