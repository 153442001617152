import {Component, h} from 'preact';
import { Link } from 'preact-router/match';
import Mobilemenutoggle from "../menumobiletoggle";
import style from './style.scss';
import { BASE_URL } from "../../config";

class Header extends Component {

	render(props, state) {

		const mainMenu = (<>
			<ul class="mainMenu" style={!props.isStudio ? { display: 'flex' } : { display: 'none' }}>
				<li><a href='#history' native>La Storia</a></li>
				<li><a href='#shops' native>I Negozi</a></li>
				<li><a href='#contacts' native>Contatti</a></li>
			</ul>
		</>);

		return (<header class={style.headMenu}>
			<div class={style.mainWrp}>
				<div class={`logo ${style.logo}`}>
					<h1>La Strega Nocciola</h1>
					<p>Gelateria Artigianale Firenze e Roma</p>
				</div>
				{/*<Mobilemenutoggle cb={props.cb} />*/}
				<nav id="mainMenu"
					class={ style.navStyle + ' main-nav menuItemCheck' }
					role="navigation"
				>

					{ mainMenu }

				</nav>
			</div>
		</header>);
	}

}

export default Header;
