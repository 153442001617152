export const BASE_URL = '/';
let staticurl = '/assets/';
let siteurl = 'https://querlo.test/';
// let siteurl = 'https://querlo.com/';

console.log('------------->staging env.preact_app_staging', process.env.PREACT_APP_STAGING, 'env.node_env', process.env.NODE_ENV);
if (process.env.PREACT_APP_STAGING === 'stage') {
	staticurl = 'https://querlo-assets-staging-cdn-endpoint.azureedge.net/public/assets/';
	siteurl = 'https://staging.querlo.com/';
}
else if (process.env.NODE_ENV === 'production') {
	staticurl = 'https://www.lastreganoccioladubai.com/assets/';
	siteurl = 'https://www.lastreganoccioladubai.com/';
}
console.log('------>', staticurl, siteurl);
export const STATIC_BASE_URL = staticurl;
export const SITE_URL = siteurl;
