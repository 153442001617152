import { h } from 'preact';
import style from './style.scss';
import { BASE_URL } from '../../config';
import { Link } from 'preact-router/match';

const Footer = () => (
	<footer class={style.foot}>
		<div className={style.mainWrp}>
			<div>
				<p>©La Strega Nocciola 2024 all rights reserved</p>
			</div>

			<ul class={ style.socials }>
				<li>
					<a href="https://www.linkedin.com/company/lastreganocciola/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin" /></a>
				</li>
			</ul>
		</div>
	</footer>
);

export default Footer;
